import * as React from 'react';

import { Helmet } from 'react-helmet';

import client from './api';
import keychain from './keychain';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Card } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { CardHeader } from '@mui/material';
import { Container } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import LoyaltyIcon from '@mui/icons-material/Loyalty';
import StarIcon from '@mui/icons-material/StarBorder';

import SponsorForm from './SponsorForm';

class Sponsor extends React.Component {
    constructor(props) {
        super(props);

        const index = Math.floor(Math.random() * 11);
        const panelImageURL = '/img/login/' + index.toString() + '.jpeg';

        this.state = {
            recurrenceSelected: undefined,
            panelImageURL: panelImageURL,
            loadingPayment: false,
            formErrors: []
        };
    }

    makePurchase = (recurrence) => {
        this.setState({recurrenceSelected: recurrence.toLowerCase()});
    }

    continueToCheckout = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let name = data.get('companyName');
        let url = data.get('companyURL');
        let zip = data.get('zipcode');
        let description = data.get('description');

        this.setState({loadingPayment: true});

        const params = {
            company_name: name,
            company_url: url,
            zipcode: zip,
            description: description,
            recurrence: this.state.recurrenceSelected
        };

        if (keychain.isLoggedIn()) {
            params['user_id'] = localStorage.getItem('user_id')
            
        }

        this.makeSponsorshipAPIRequest(params);
    }

    makeSponsorshipAPIRequest = (params) => {
        client.post('sponsorships/', params)
        .then(res => {
            window.location.replace(res.data);
        })
        .catch(error => {
            if (error.response.status === 400) {
                let errorData = error.response.data;
                let errorKeys = Object.keys(errorData);
                this.setState({formErrors: errorKeys, loadingPayment: false});
            } else {
                this.setState({loadingPayment: false});
            }
        });
    }

    render() {
        var loginButton = <Box />;
        if (keychain.isLoggedIn()) {
            loginButton = <Grid container>
                <Grid item>
                <Link href="/sponsor/manage" variant="body2" sx={{my:2, mx:4}}>
                    Manage your sponsorships
                </Link>
                </Grid>
            </Grid>
        }
        else {
            loginButton = <Grid container>
                <Grid item>
                <Link href="/login" variant="body2" sx={{my:2, mx:4}}>
                    Login for easier Sponsorship management
                </Link>
                </Grid>
            </Grid>
        }

        const tiers = [
            {
            title: 'Monthly',
            price: '9.99',
            recurrence: 'mo',
            long_recurrence: 'month',
            buttonText: 'Purchase',
            buttonVariant: 'contained',
            },
            {
            title: 'Annual',
            subheader: 'Most popular',
            price: '99',
            recurrence: 'yr',
            long_recurrence: 'year',
            buttonText: 'Purchase',
            buttonVariant: 'contained',
            },
        ];

        var sponsorContent = <Box />;
        if (this.state.recurrenceSelected === undefined) {
            sponsorContent = <><Typography component="h1" variant="h6" align='center' color="text.secondary" sx={{ mt: 2, mx: 4 }}>
                Pet owners in your neighborhood can send missing pet alerts for free.
                </Typography><Typography component="h1" variant="h6" align='center' color="text.secondary" sx={{ mt: 2, mx: 4 }}>
                    Your name and link will be prominently displayed in our apps for all to see.
                </Typography><Container maxWidth="md" component="main">
                    <Grid container spacing={2} alignItems="flex-end" sx={{ mt: 6, mb: 4 }}>
                        {tiers.map((tier) => (
                            // Enterprise card is full width at sm breakpoint
                            <Grid
                                item
                                key={tier.title}
                                xs={12}
                                sm={12}
                                md={6}
                            >
                                <Card>
                                    <CardHeader
                                        title={tier.title}
                                        subheader={tier.subheader}
                                        titleTypographyProps={{ align: 'center', fontWeight: 600 }}
                                        action={tier.title === 'Annual' ? <StarIcon /> : null}
                                        subheaderTypographyProps={{
                                            align: 'center',
                                        }}
                                        sx={{
                                            backgroundColor: 'action.selected'
                                        }} />
                                    <CardContent>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'baseline',
                                                my: 2,
                                            }}
                                        >
                                            <Typography component="h2" variant="h4" color="text.primary">
                                                ${tier.price}
                                            </Typography>
                                            <Typography variant="h6" color="text.secondary">
                                                /{tier.recurrence}
                                            </Typography>
                                        </Box>
                                    </CardContent>
                                    <CardActions>
                                        <Button fullWidth variant={tier.buttonVariant} onClick={() => { this.makePurchase(tier.long_recurrence); } } sx={{ textTransform: "none", fontWeight: 600 }}>
                                            {tier.buttonText}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
                {loginButton}</>
        }
        else {
            sponsorContent = <SponsorForm 
                handleSubmit={this.continueToCheckout}
                loadingPayment={this.state.loadingPayment}
                errors={this.state.formErrors}/>;
        }

        return (
            <React.Fragment>
                <Helmet>
                    <title>Spot - Sponsor pet safety in your neighborhood</title>
                    <meta name="og:title" content="Spot - Sponsor pet safety in your neighborhood" />
                    <meta name="description" content="Help reunite families with their pets by sponsoring pet safety in your area." />
                    <meta name="og:description" content="Help reunite families with their pets by sponsoring pet safety in your area." />
                    <meta name="og:image" content="https://pbs.twimg.com/media/E-oUCiWVEAAPY8c?format=jpg&name=small" />
                </Helmet>
                <Grid container component="main" sx={{ height: '100vh' }}>
                    <CssBaseline />
                    <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${this.state.panelImageURL})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                    />
                    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                        my: 8,
                        mx: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LoyaltyIcon />
                        </Avatar>
                        <Typography component="h1" variant="h4" align='center' sx={{my:2, mx:4}}>
                            {this.state.recurrenceSelected === undefined ? "Sponsor Pet Safety in your Neighborhood" : "Tell us a little about yourself"}
                        </Typography>
                        {sponsorContent}
                    </Box>
                    </Grid>
                </Grid>
            </React.Fragment>
    );
  }
}

export default Sponsor;
